import { onboardingStore } from "~/shared/pinia-store/onboarding";


export const useonGoingOnBoarding = (): any => {
  const { ongoingOnboarding, currentStep, stepForward } = onboardingStore();
  const onBoardingClickedAction = () => {
    if (ongoingOnboarding.value && currentStep.value === 19) {
      stepForward();
    }
  };


  return {
    onBoardingClickedAction 
  };
};
