<template>
  <div class="autosaver">
    <ion-toggle 
      v-model="localAutosaveEnabled" 
      label-placement="start" 
      class="toggle"
    >Autosave</ion-toggle>
    <div class="my-2 details" :class="[horizontal && 'd-inline-block ml-3']">
      <div v-if="isSaving" :style="`color: ${updateColor} !important;`">Saving...</div>
      <div v-else-if="formattedModified" :style="`color: ${updateColor} !important;`">Updated {{ formattedModified }}</div>
      <div v-else-if="!isSaving && !autosaveEnabled && isDirty" :style="`color: ${updateColor} !important;`">Unsaved changes</div>
      <div v-else>&nbsp;</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { formatTimeAgo } from '@/shared/utils/dateTime';
const props = defineProps({
  isSaving: {
    type: Boolean,
    default: false,
  },
  isDirty: {
    type: Boolean,
    default: false,
  },
  autosaveEnabled: {
    type: Boolean,
    default: false,
  },
  setAutosaveEnabled: {
    type: Function,
    default: () => {}
  },
  modified: {
    type: String,
    default: '',
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  updateColor: {
    type: String,
    default: 'white',
  },
});
const isSaving = toRef(props, 'isSaving');
const isDirty = toRef(props, 'isDirty');
const modified = toRef(props, 'modified');
const autosaveEnabled = toRef(props, 'autosaveEnabled');
const setAutosaveEnabled = toRef(props, 'setAutosaveEnabled');
const localAutosaveEnabled = ref(autosaveEnabled.value);
watch(localAutosaveEnabled, (newValue) => {
  setAutosaveEnabled.value(newValue);
});
watch(autosaveEnabled, (newValue) => {
  if (newValue !== localAutosaveEnabled.value) localAutosaveEnabled.value = newValue;
});

const formattedModified = ref('');
const labelInterval = ref(null as any);
const isNew = computed(() => {
  return !modified.value;
});
const updateFormattedModified = () => {
  if (modified.value) {
    formattedModified.value = formatTimeAgo(modified.value);
    if (formattedModified.value.startsWith('in a ')) formattedModified.value = 'just now';
  } else formattedModified.value = '';
};

watch(modified, updateFormattedModified);

onMounted(() => {
  if (modified.value) updateFormattedModified();
  labelInterval.value = setInterval(updateFormattedModified, 3000);
});

onBeforeUnmount(() => {
  clearInterval(labelInterval.value);
});
</script>

<style lang="sass" scoped>
.autosaver
  display: block
.toggle
  user-select: none !important
.details
  color: gray
  font-size: 0.8rem
  line-height: 1.1rem
ion-toggle
  zoom: 0.75
  font-size: 1.2rem
</style>
