<template>
  <div
    id="app-nav-bar"
    class="nav-bar d-flex flex-column justify-content-center align-items-center position-relative"
    :class="{ isXmas, lightRope }"
  >
    <div class="content">
      <DesktopNavBar v-if="isDesktopSize" />
      <MobileNavBar v-else />
    </div>
    <EditorTopBar v-if="editorPage" :type="editorPage" />
  </div>
</template>

<script lang="ts" setup>
import DesktopNavBar from './DesktopNavBar.vue';
import MobileNavBar from './MobileNavBar.vue';
import EditorTopBar from './EditorTopBar.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import { mainStore } from '@/shared/pinia-store/main';

const characterEditorPages = [
  'edit-char-draft',
  'draft-character-editor',
  'character-editor',
  'character-creator',
  'preset-creator',
  'preset-editor',
];
const worldEditorPages = ['create-story-world', 'draft-story-world', 'edit-world'];
const socialSpaceEditorPages = ['create-social-space', 'edit-social-space'];
const isXmas = featureFlags.isXmas;
const defaultPageRoute = computed(() => {
  try {
    const router = useRouter();
    return router.currentRoute.value.name;
  } catch (error) {
    return '';
  }
});
const editorPage = computed(() => {
  if (characterEditorPages.includes(defaultPageRoute.value as string)) return 'character';
  if (worldEditorPages.includes(defaultPageRoute.value as string)) return 'world';
  if (socialSpaceEditorPages.includes(defaultPageRoute.value as string)) return 'socialSpace';
  return null;
});

const { isDesktopSize } = useWindowSize();

const lightRope = computed(() => {
  const { lightRope } = mainStore();
  return lightRope.value;
});
</script>

<style scoped lang="sass">
.content
  max-width: 1500px
  width: 100%
.nav-bar
  z-index: 112
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF
  box-shadow: 0px 4px 6px 0px rgba(34, 17, 39, 0.12)
</style>
