<template>
  <div class="h-100">
    <router-link to="/signup">
      <ClientOnly>
        <ion-button fill="none" class="inline-button text-white">Sign Up</ion-button>
      </ClientOnly>
    </router-link>

    <router-link to="/login">
      <ion-button v-if="!isLoginPage" fill="none" class="icon-button inline-button text-white ml-2"
        ><i class="ti-user" /></ion-button
    ></router-link>

    <router-link :to="{ name: 'marketplace' }" class="mx-1">
      <ion-button fill="none" class="icon-button inline-button text-white">
        <i class="ti-shopping-cart" />
      </ion-button>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
const isLoginPage = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.path.includes('login');
});
</script>

<style lang="sass" scoped>
.inline-button
  background: rgba(255, 255, 255, 0.1)
  border-radius: 6px
  text-transform: unset !important
  color: #FFF
.icon-button
  width: 28px
</style>
