<template>
  <div
    class="logo h-100 d-flex align-items-center justify-content-center px-1"
    :class="{ 'is-disabled': ongoingOnboarding && !isStepActive(18) }"
  >
    <a id="o-logo" class="clickable-item-hov" href="/" @click.stop.prevent="navigateHome">
      <img alt="CharacterHub" loading="lazy" v-if="!dark && !isXmas && !userLogo" src="/logo-white.png" />
      <img id="o-logo" alt="CharacterHub" loading="lazy" v-else-if="!dark && isXmas" src="/logo-white-xmas.png" />
      <img
        v-else-if="!dark && userLogo"
        src="https://prod-assets.characterhub.com/logo/logo-aeesth.png"
        alt="aeesth's CharacterHub Logo"
        title="Logo done by @aeesth!"
        loading="lazy"
        class="ulogo"
      />
      <inline-svg v-else-if="dark && !isXmas && !userLogo" height="30px" width="176px" src="/logo-dark.svg" />
      <inline-svg v-else-if="dark && isXmas" class="xhl pl-sm-5 pl-2" src="/logo-dark-xmas.svg" />
      <img
        v-else-if="dark && userLogo"
        src="https://prod-assets.characterhub.com/logo/logo-aeesth.png"
        alt="aeesth's CharacterHub Logo"
        title="Logo done by @Hollow!"
        loading="lazy"
        class="ulogo"
      />
    </a>
  </div>
</template>

<script lang="ts" setup>
import { featureFlags } from '@/shared/config/feature-flags';
import { mainStore } from '@/shared/pinia-store/main';
import { useonGoingOnBoarding } from '~/composables/useonGoingOnBoarding';
import { onboardingStore } from '~/shared/pinia-store/onboarding';

const { onBoardingClickedAction } = useonGoingOnBoarding();
const isXmas = featureFlags.isXmas;
const userLogo = featureFlags.userLogo;
const { ongoingOnboarding, isStepActive } = onboardingStore();
const { dark } = mainStore();

const router = useRouter();

const navigateHome = () => {
  if (ongoingOnboarding) {
    onBoardingClickedAction();
  }
  if (router.currentRoute.value.path !== '/' && router.currentRoute.value.path !== '/myhome') {
    router.push('/');
  }
};
</script>

<style scoped lang="sass">

.logo
  border-radius: 12px
  height: 32px
  image-rendering: auto
  position: relative
  overflow: hidden
  box-sizing: content-box
  img
    height: 28px
  .ulogo
    height: 44px
  ::v-deep
    svg
      filter: drop-shadow(0px 0px 7px #da00ff)
  &.small
    width: 34px
@media (max-width: 400px)
  .ulogo
    height: 36px !important
.logo:before
  pointer-events: none
  content: ''
  position: absolute
  top: 0
  left: -100px
  width: 70px
  height: 100%
  background: rgba(255,255,255, 0.3)
  transform: skewX(-30deg)
  animation-name: slide
  animation-duration: 7s
  animation-timing-function: ease-in-out
  animation-delay: .3s
  animation-iteration-count: infinite
  animation-direction: alternate
  background: linear-gradient(to right,rgba(255, 255, 255, 0.13) 0%,rgba(255, 255, 255, 0.13) 77%,rgba(255, 255, 255, 0.5) 92%,rgba(255, 255, 255, 0.0) 100%)
</style>
