<template>
  <div class="desktop-nav-bar d-flex align-items-center justify-content-between w-100">
    <div class="content d-flex justify-content-between align-items-center px-3">
      <div class="start" />
      <div class="center">
        <ChLogo />
      </div>

      <div class="end d-flex justify-content-end" :class="{ minn: !isAuthenticated }">
        <SearchBar :clear-value="true" v-if="isAuthenticated && !isSearchPage && !ongoingOnboarding" class="srch-bar" />
        <NonAuthenticatedButtons v-if="!isAuthenticated" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChLogo from './ChLogo.vue';
import SearchBar from './SearchBar.vue';
import NonAuthenticatedButtons from './NonAuthenticatedButtons.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { onboardingStore } from '~/shared/pinia-store/onboarding';

const { isAuthenticated } = authStore();
const { ongoingOnboarding } = onboardingStore();
const router = useRouter();
const isSearchPage = computed(() => router.currentRoute.value.name === 'search');
</script>

<style scoped lang="sass">
.minn
  min-width: 150px
.srch-bar
  width: 95% !important

.desktop-nav-bar
  height: var(--default-nav-bar-height)
.content
  width: 100%
.center
  max-width: 650px
  min-width: 650px
  width: 100%
.start, .end
  width: 100%
.end
  margin-right: 25px
  @media(max-width: 1460px)
    margin-right: 10px
  @media(max-width: 1440px)
    margin-right: 0px
</style>
