<template>
  <div class="position-relative" style="width: 40px">
    <img :src="get(user.customize_profile, 'profile_ring.image')" class="border-image" />

    <div
      class="my-0 profile-picture d-flex align-items-center justify-content-center"
      :style="{
        backgroundImage: `url(${profilePicture})`,
        border: profilePicture ? 'none' : `1px solid ${dark ? '#444' : '#CCC'}`,
      }"
    >
      <span v-if="!profilePicture">{{ profileInitial }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

const { user } = authStore();

const props = defineProps({
  width: { type: Number },
  height: { type: Number },
  image: { type: String, default: '' },
});
const { dark } = mainStore();
const width = toRef(props, 'width');
const height = toRef(props, 'height');
const profilePicture = computed(() => {
  return resizeUpload(user.value.profile_picture_url, '80x80');
});

const imageWidth = computed(() => {
  return width.value ? `${width.value}px` : '28px';
});

const imageHeight = computed(() => {
  return height.value ? `${height.value}px` : '28px';
});
const profileInitial = computed(() => {
  return user.value?.username?.charAt(0);
});
</script>

<style lang="sass" scoped>
.border-image
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  z-index: 3
  position: absolute

.profile-picture
  cursor: pointer
  width: v-bind(imageWidth)
  height: v-bind(imageHeight)
  min-width: v-bind(imageWidth)
  background-color: var(--ion-color-primary) !important
  color: #FFF
  border-radius: 40px
  background-size: cover
  background-position: center
  z-index: 2
  position: absolute
  transform: translateX(-50%) translateY(-50%)
  top: 50%
  left: 50%
  font-size: 13px

.xmas-hint
  height: 25px
  .text
    font-size: 10px
    width: 100px
    color: var(--ion-color-primary)
    left: -120px
    text-align: end
  .hubert
    width: 16px
    transform: rotate(-25deg)
    left: -10px
    top: -5px
    img
      z-index: 1
  .dot1, .dot2
    width: 4px
    height: 4px
    position: absolute
    z-index: 5
    background: var(--ion-color-primary)
    border-radius: 5px
  .dot1
    left: 18px
    top: 7px
  .dot2
    left: 4px
    top: 22px
ion-menu-toggle
  display: flex
  align-items: center
@media(max-width: 1320px)
  .menu-btn
    display: block !important
.menu-btn
  display: none
  color: #ae38e5
  --padding-start: 0
  --padding-end: 0
  margin: 0
  height: 100%
  width: 64px
</style>
